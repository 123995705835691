
.collateralView-header {
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 0px 24px;
  .iconTextButton {
    background-color: white;
  }
}