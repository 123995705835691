
.uploadProjectFiles-uploadIcon {
  path {
    fill: black;
  }
}
.uploadProjectFiles-uploadLabel span {
  font-weight: normal;
  font-size: 14px;
}
.uploadProjectFiles-uploadLabel.disabled {
  span {
    color: #cbcbcb;
  }
  svg path {
    fill: #cbcbcd;
  }
}

.uploadProjectFiles-suggestedDocumentsList li{
  display: flex;
  align-items: center;
  &::before{
    content: "○"; 
    font-size: 24px;
    line-height: 26px;
    color: #74C3E1;
    padding-right: 5px;
  }
}