@screen xl {
    .xl\:w-37\.5w {
        width: 37.5vw;
    }
}

@screen md {
    .md\:bg-contain-45w {
        background-size: 45vw;
    }
}