.checkbox {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.checkbox-small {
    width: 15px;
    height: 15px;
}

.checkbox:not(.checked):not(.disabled):hover::before {
    content: url("../../assets/images/checkmark.svg");
    color: white;
    position: relative;
}

.checkbox-small:not(.checked):not(.disabled):hover::before {
    content: url("../../assets/images/checkmark-small.svg");
    top: -11px;
    left: 0px;
}

.checkbox.checked:not(.disabled) {
    box-shadow: 0 4px 6px -1px #FFED9D, 0 2px 4px -1px #FFED9D;
}

.checkbox.checked:not(.disabled)::before {
    content: url("../../assets/images/checkmark.svg");
}

.checkbox-small.checked:not(.disabled)::before {
    content: url("../../assets/images/checkmark-small.svg");
    position: relative;
    top: -11px;
}