
.header {
  .logo img {
      position: relative;
      left: -20px;
      top: 24px;
      
  }
  margin-top: 10px;
}
.partnershipText {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-bottom: 2px;
  span {
  color: #6D6D70;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 200;
}}
