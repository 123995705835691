.pdf-scroll {
  scrollbar-color: #909090 transparent;
  scrollbar-width: thin;
}

.pdf-scroll::-webkit-scrollbar {
  width: 4px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  opacity: 0.5;
}

/* Optional: show position indicator in red */
.pdf-scroll::-webkit-scrollbar-thumb {
  border-bottom: 4px solid #909090;
  opacity: 0.5;
}

/* Optional: show position indicator in red */
.pdf-scroll::-webkit-scrollbar-thumb:vertical {
  border-left: 4px solid #909090;
  opacity: 0.5;
}
