
.titleBar{
  .title {
    height: 16px;
    td:first-child{
      font-weight: 600;
      border-left: 0px;
      padding-left: 0px;
    }
    td {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 16px;
      border-left: 1px #6D6D70 solid;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .activateFinancingButton {
    height: 32px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50px;
    border: 0px;
    background: white;
    color: #343333;
    font-size: 16px;
    font-weight: normal;
  }
  .activateFinancingButton:hover{
    background:#F0F0F0;
  }
  .activateFinancingButton > .dropdownMenu-label {
    font-weight: normal;
  }
}