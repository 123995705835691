

.marketingTextDiv{
  margin-bottom: 30px;
}


.projectInfo{
  font-size: 20px;
  margin-top: 14px; 
  margin-left: 20px;
  color: #343333;
}