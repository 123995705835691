
.companiesTable {
  width: 100%;
  th{
    font-weight: 700;
  }
  td{
    font-weight: 400;
  }
  td, th {
    padding: 12px 16px;
    text-align: start;
    font-size: 14px;
    line-height: 16px; /* 114.286% */
    
  }
  tr{
    border-left: 1px solid #ffffff00;
    border-right: 1px solid #ffffff00;
  }
  thead tr {
    border-top: 1px solid #ffffff00;

  }
  tbody tr{
    // border: 1px solid transparent;
    border-top: 1px solid #f0f0f0FF;
  }
  tbody tr:hover{
    border: 1px solid #FFD204;
    border-radius: 3px;
  }
}