* {
    font-family: "source-sans-pro"
}

#root {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    opacity: 1;
}

div.dateInput {
    margin-top: 2px;
    width: 160px;
    border-color: rgba(237, 242, 247, var(--tw-border-opacity));

    >div {
        padding-right: 0px;
        border-color: rgba(237, 242, 247, var(--tw-border-opacity));
    }

    >div {
        input:focus {
            ~fieldset {
                border-color: #FFD204;
                border: 1px #FFD204 solid !important;
            }
        }
    }

    input {
        font-family: "source-sans-pro";
        padding: 9px;
        padding-right: 0px;
    }
}

.MuiPopover-root {
    .MuiPickersDay-daySelected {
        background-color: #FFD204;

    }

    .MuiToolbar-gutters {
        background-color: #767676;
    }

}

input {
    outline: none;
}

.iconButton {
    img {
        width: 20px;
        height: 20px;
    }

    background-color: transparent;
    padding: 6px;
    border-radius: 900px;
}

.iconButton:hover:not(.disabled) {
    background-color: #F0F0F0;
}

.iconButton-disabled {
    svg path {
        fill: #f0f0f0;
    }
}

.iconButton-disabled:hover {
    background-color: transparent;
}


button {
    font-size: 20px;
}

.borderGrayLighter {
    border: 1px solid #F0F0F0;
}

.borderGray {
    border: 1px solid #CBCBCD;
}

.borderGrayDarker {
    border: 1px solid #6D6D70;
}

.borderBlueLight {
    border: 1px solid #74C3E1;
}

.borderYellow1 {
    border: 1px solid #FFD204;
}

.fontGray {
    color: #CBCBCD;
}

.gray2 {
    color: #6D6D70;
}

.bgGray2 {
    background-color: #F0F0F0;
}

.autocomplete__option:hover {
    background-color: #fff1b0;
}

.financing-portal {

    .error input,
    input.error {
        border-color: #ee3b3b;
    }

    input {
        outline: none;
    }

    h6 {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    h4 {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
    }

    h3 {
        font-size: 32px;
        font-weight: 300;
        line-height: 40px;
    }

    h2 {
        font-size: 40px;
        font-weight: 300;
        line-height: 64px;
        /* 160% */
    }

    h1 {
        font-size: 64px;
        font-weight: 200;
        line-height: 80px;
        /* 125% */
    }

    .iconBlack {
        path {
            fill: #000;
        }
    }

    font-family: "source-sans-pro";

    h4.font-normal {
        font-weight: 400;
    }

    .s14 {
        font-size: 14px;
    }

    .s16 {
        font-size: 16px;
    }

    .s18 {
        font-size: 18px;
    }

    .s24 {
        font-size: 24px;
    }

    .userName {
        padding-top: 8px;
    }

    input:disabled,
    select:disabled {
        border: 1px solid #F0F0F0;
        color: #6D6D70;
        background-color: white;
    }

    div.disabled>svg>path {
        fill: #F0F0F0;
    }

    span.checkbox.checked.disabled {
        border-color: #6D6D70;
    }

    span.checkbox {
        box-shadow: none !important;
        border-color: #6D6D70;
    }

    span.checkbox.checked {
        border-color: #FFD204;
    }

    .addPaymentForm {
        white-space: nowrap;
    }

    .content {
        left: 0px;
        height: 100%;
        width: 100%;
    }

    .radio::before {
        z-index: 0;
    }

    .svgBlack {
        width: 24px;
        height: 24px;

        path {
            fill: #343333;
        }
    }

    .window {
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 7px 0px rgba(109, 110, 113, 0.32);
        border-radius: 5px;
        opacity: 1;
        min-width: 800px;
        max-width: 1300px;
        width: 97%;
        white-space: normal;
        margin-bottom: 60px;
    }

    .form-window-default-size {
        min-width: 100%;
        min-height: 620px;
        padding: 24px;
        margin-bottom: 10px;
    }

    .form-window-big-size {
        height: 960px;
    }

    .form-window-results {
        padding: 24px;
    }

    button.icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }

    .step {
        color: #343333;
        display: flex;
        font-size: 16px;
        justify-content: flex-start;

        .numberField {
            display: inline-block;
            margin-right: 8px;
            width: 185px;
        }

        .radioField {
            .infoTooltipLabel {
                margin-bottom: 8px;

                label {
                    margin-right: 4px;
                }
            }

            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
            width: 220px;
        }

        .sliderField {
            font-family: 'source-sans-pro';
            display: inline-block;
            vertical-align: top;
            margin-right: 8px;
            width: 180px;

            .slider {
                margin-top: 8px;
            }
        }

        input,
        select,
        span {
            font-size: 16px;
        }

        .percentageInsideNumberField {
            font-size: 16px;
            position: relative;
            right: 28px;
            display: inline-block;
        }

        .advancedSection {
            font-size: 14px;
            display: inline-block;
            margin-top: 4px;

            img {
                margin: 0 auto;
                margin-bottom: 5px;
            }

            div {
                width: 75px;
                text-align: center;
            }

            border-left: 1px #F0F0F0 solid;
            padding-left: 8px;
        }
    }

    .errorLabel {
        margin-top: 0px;
        margin-bottom: 4px;
        min-height: 30px;
        font-size: 20px;
    }

    .labelDetail {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .nav-buttons {
        position: absolute;
        bottom: 32px;
        right: 32px;
    }

    .submitButton {
        width: 200px;
        height: 40px;
        margin-right: 8px;
        font-weight: bold;
    }

    .nav-buttons .spinner {
        position: absolute;
        bottom: 10px;
        left: 125px;
    }

    .v-align-middle {
        position: relative;
        top: 45%;
        transform: translateY(-50%);
    }

    .h-align-middle {
        margin: 0 auto;
    }

    Label,
    input {
        color: #343333;
        text-align: left;
        margin-top: 2px;
    }

    Label {
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
    }

    .withLabelDetail {
        margin-bottom: 4px;
    }

    .addressLabel {
        margin-bottom: 12px;
    }

    .inputField {
        input:not(.error):not(:disabled):not(.border-en-red) {
            border: 1px solid #343333;
        }

        input:focus:not(.error) {
            border: 1px solid #FFD204;
        }

        svg {
            top: 5px;
        }
    }

    .selectField {
        width: 100%;

        svg {
            top: 5px;
        }

        input:not(.error):not(:disabled) {
            border: 1px solid #343333;
        }

        input:focus {
            border-color: #FFD204;
        }
    }

    .selectField:focus {
        border-color: #FFD204;
    }
}

li.autocomplete__option {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: nowrap;
}

.addressFields {
    .selectField {
        svg {
            top: -5px;
        }
    }

    input {
        margin-bottom: 0px;
    }

    Label {
        margin-bottom: 2px;
    }

}

.addressFields>div {
    margin-bottom: 10px;
}

.sliderStep {
    .slider {
        margin-top: 18px;

        span {
            font-size: 16px;
        }
    }
}

.summaryStep {
    color: #343333;
    padding-top: 20px;

    .title {
        font-size: 40px;
        font-weight: bold;
        padding-left: 4px;
    }

    .subtitle {
        font-size: 24px;
        margin-bottom: 20px;
        padding-left: 4px;
    }

    .disclaimer {
        background: #FCD0041A 0% 0% no-repeat padding-box;
        padding: 16px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .summaryTable {
        margin: 0 auto;
        font-size: 18px;

        .stepNumber {
            padding-right: 10px;
            padding-left: 4px;
            vertical-align: top;
        }

        .stepLabel {
            padding-right: 24px;
            color: #767676;
            vertical-align: top;
        }

        .stepValue {
            color: #37393f
        }
    }
}

.thanksStep {
    .title {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
    }

    .information {
        text-align: center;
    }

    .newRequest {
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 14px;
        padding-right: 14px;
        position: absolute;
        left: 220px;
        margin-top: 30px;
    }
}