
.archiveTable { 
  .tableActions {
    display: flex;
    gap: 24px;
  }
  table{
    width: 100%;
    th{
      font-weight: 700;
    }
    td{
      font-weight: 400;
    }
    td, th {
      padding: 12px 16px;
      text-align: start;
      font-size: 14px;
      line-height: 16px; /* 114.286% */ 
    }
    td.actionsTd{
      padding-top: 2px;
      padding-bottom: 2px;
      display: flex;
      justify-content: center;
      button {
        padding: 4px;
      }
      button:hover {
        background-color: #F0F0F0;
        border-radius: 999px;
      }
    }
    tr{
      border-left: 1px solid #ffffff00;
      border-right: 1px solid #ffffff00;
    }
    thead tr {
      border-top: 1px solid #ffffff00;

    }
    tbody tr{
      // border: 1px solid transparent;
      border-top: 1px solid #f0f0f0FF;
    }
    tbody tr:hover{
      border: 1px solid #FFD204;
      border-radius: 3px;
    }
  }
  span.checkbox::before{
    top: -9px !important;
  }
}
