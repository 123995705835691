.businessCompanySearchModal {
  .modal-content{
    max-width: 708px;
    display: flex;
    flex-direction: column;
  }
  position: absolute;
  font-size: 16px;
}
.businessCompanySearchModal-companiesList {
  height: 442px;
  background-color: #fafafa;
  border-radius: 8px;
  > div {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 410px;
  }
}
.businessCompanySearchModal-companyCard{
  border-radius: 8px;
  background-color: white;
  > div {
    line-height: 22px;
  }
  padding: 8px 12px;
  &:hover {
    border: 1px solid var(--UI-Divider, #CBCBCD);
    padding: 7px 11px;

    /* 01 Shadows/Light/z8 */
    box-shadow: 0px 3px 5px 0px rgba(109, 110, 113, 0.32);
    cursor: pointer;
  }
  &.selected {  
    border: 1px solid var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
    box-shadow: 0px 3px 5px 0px rgba(109, 110, 113, 0.32);
    padding: 7px 11px;
  }
}
.businessCompanySearchModal-button {
  width: 180px;
  justify-content: center;
  height: 48px;
}