
.creditApp-projectDetailsStep{
  textarea {
    width: 100%;
    padding: 4px 8px;
    resize: none;
    border: 1px solid #6D6D70;
    border-radius: 0.375rem;
    margin-right: 20px;
    box-sizing: border-box;
    outline: none;
  }
  textarea.invalid {
    border: 1px solid #F45C5C;
  }
  textarea:focus{
    border: 1px #FFD204 solid;
    caret-color: #FFD204;
  }
  .incentivesDescriptionDiv{
    max-width: 100%;
  }
  .numberInput, .numberInput > div  {
    width: 100%;
  }
  .progressPayments {
    background-color: white;
    font-size: 16px;
    border-radius: 4px;
    padding: 6px 8px;
    width: fit-content;
    display: flex;
    display: flex;
    gap: 8px;
  }
  .progressPayment {
    background-color:#fafafa;
    padding: 0px 8px;
    border-radius: 4px;
  }
}
.creditApp-form.partnerView {
  padding-top: 15px;
  > div {
    padding-top: 24px;
  }
}