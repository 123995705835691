.incentivesField:not(.disabled) {
  background-color: #f0f0f0;
  .incentiveList span {
    background-color: white;
  }
}
.incentivesField.disabled {
  background-color: #f0f0f0;
  color: #cbcbcb;
  svg path {
    fill: #cbcbcd;
  }
}
.incentivesField {
  font-size: 16px;
  border-radius: 4px;
  padding: 6px 8px;
  width: fit-content;
  display: flex;
  .incentiveList{ 
    display: flex;
    gap: 8px;
    span {
      background-color: white;
      padding: 0px 8px;
      border-radius: 4px;
    }
  }
  .noIncentives {
    color: #cbcbcb;
  }
}
.incentivesField:hover:not(.disabled) {
  cursor: pointer;
  background-color: #E3E3E3;
}