

//
.informationPopover {
  position: relative;
  display: inline-block;
}
  
.informationPopover-overlay {
  position: absolute;
}
.informationPopover-content {
  width: 100%;
  display: flex;
  color: white;
  p, div {
    color: white;
  }
  font-size: 16px;
  padding: var(--Gap-lg, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  flex: 1 0 0;
  border-radius: 4px;
  background: var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
  position: absolute;
  z-index: 1;
}

.informationPopover-title {
  color: var(--Text-Light-Text-02-Light-Text, #FFF);
  font-size: 20px;
  font-weight: 600;
}
.informationPopover-closeButton {
  display: flex;
  color: var(--EnPowered-Primary-02-Grey-Dark, #343333);
  font-size: 16px;
  height: 32px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Common-Background-02-Paper, #FFF);
  float: right;
  font-weight: 600;

  /* 01 Shadows/Dark/z1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.fadein {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}