
.financingScheduleView {
  color: #37393F;
  white-space: normal;
  padding-bottom: 30px;
  .lineSeparator{
    border: 1px #F0F0F0 solid;
    border-bottom: none;
    margin-left: 0px;
    margin-right: 0px;
  }
  h4{
    font-size: 24px;
    font-weight: 300;
    padding-top: 20px;
    padding-bottom: 15px;
  }
  h6 {
    font-size: 18px;
    font-weight: 600;
  }
  label {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  .fieldBlock {
    .value {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.schedulingViewTr-firstYear {
  background-color: #fafafa;
  td {
    width: 150px;
    vertical-align: bottom;
  }
  &.header td h5{
      height: 30px;
      margin-top: 16px;
      margin-left: 20px;
      margin-right: 12px;
    }
  td:first-child .fieldBlock {
    margin-left: 20px;
  }

  .fieldBlock {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
  }
}

.financingScheduleView-mainValuesTable tr:not(.schedulingViewTr-firstYear) td {
  vertical-align: bottom;
  width: 150px;
  height: 100px;
  &:first-child .fieldBlock {
    margin-left: 20px;
  }
  .fieldBlock{
    margin: 12px 8px; 
  }
}