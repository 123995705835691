.iconTextButton.noIcon {
  padding: 8px 16px 8px 16px;
}

.iconTextButton.outlined {
  border: 1px solid #6D6D70;
}

.iconTextButton.disabled {
  background-color: #CBCBCD;
  color: #f0f0f0;
}

.iconTextButton.filled.primary:not(.disabled) {
  background-color: #FFD204;
}

.round {
  border-radius: 999px;
}

.iconTextButton.filled:not(.disabled) {
  background-color: #f0f0f0;
  padding: 12px 16px 12px 16px;
}

.iconTextButton {
  &:not(.round) {
    border-radius: 4px;
  }

  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  background-color: transparent;
  padding: 5px 16px 5px 8px;
}

.iconTextButton.font-normal span {
  font-weight: 400;
  border-radius: 4px;
}

.iconTextButton:hover:not(.disabled) {
  background-color: #fafafa;
  box-shadow: none;
}

.borderBlueLight {
  border: 1px solid #74C3E1;
}