.proposalView{
  color: #37393F;
  white-space: normal;

  h3{
    font-weight: 300;
    font-size: 32px;
    padding-top: 25px;
  }
  .actions {
    button {
      img {
        width: 20px;
        height: 20px;
      }
      box-shadow: none;
    }
    .iconButton {
      img {
          width: 20px;
          height: 20px;
      }
      background-color: transparent;
      padding: 6px 6px 6px 6px;
      border-radius: 900px;
    }
    .iconButton:hover{
        background-color: #F0F0F0;
    }
    .iconButton-disabled {
        svg path{
            fill: #f0f0f0;
        }
    }
    .iconButton-disabled:hover{
        background-color: transparent;
    }
    .textButton {
      position: relative;
      top: -5px;
      span {
        padding-top: 1px;
      }
      color: #343333;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      background-color: #FFD204;
      border-radius: 4px;
      padding: 6px 16px;
    }
  }
  .greenTag {
    border-radius: 50px;
    background-color: #98CB65;
    margin-left: 8px;
    padding: 4px 12px;
    display: inline-block;
    margin: 0 auto;
    position: relative;
    left: -10px;
  }
  .greenCell {
    background-color: #E6F4E2;
  }
  .redCell {
    background-color: rgba(244, 92, 92, 0.16);
  }
  .erroLabel {
    margin-top: 8px;
  }
  .snackbar div{
    font-size: 12px;
    font-family: source-sans-pro;
  }
  .blankFieldValue {
    color: #CBCBCB;
    font-weight: 300;
  }
}