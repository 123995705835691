
.fieldBlock {
  font-size: 18px;
  color: #343333;
  label {
  }
  .value {
    font-weight: 700;
  }
}

.highlightedValuesLine {
  background-color: #fafafa;
  border-bottom: 1px #f0f0f0 solid;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
}

.suggestedPaymentSchedule{
  color: #343333;
  height: 1220px;
}

.annualPaymentTable {
  font-size: 15px;
  tr {
    border-top: 1px #f0f0f0 solid;
    border-bottom: 1px #f0f0f0 solid; 
    text-align: center;
    text-align: left;
    td{
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 12px;
    }
    td:first-child{
      text-align: center;
      padding-right: 16px;
    }
    th {
      padding-left: 12px;
      padding-right: 16px;
    }
    th:first-child{
      text-align: center;
    }
  }
  
  margin: 0 auto;
  width: 100%;
}


.greenCell {
  background-color: #E6F4E2;
}
.redCell {
  background-color: rgba(244, 92, 92, 0.16);
}


.greenTag {
  border-radius: 50px;
  background-color: #98CB65;
  margin-left: 8px;
  padding: 4px 12px;
  display: inline-block;
  margin: 0 auto;
  position: relative;
  left: -10px;
}