.form-button {
  @apply shadow-md bg-white relative rounded p-2 flex items-center cursor-pointer transition duration-500;
  min-height: 74px;
  max-width: 310px;
}

.form-button:hover {
  @apply shadow-lg
}

.form-button * {
  @apply cursor-pointer;
}