.basicInfoStep {
  flex-direction: column;
  .termLengthField {
    position: relative;
    .textInputSmall span span {
      position: absolute;
      left: 16px;
    }
  }
  .savingsField {
    margin-top: 1px;
  }
}
