.creditAppPage{
  background-color: #FAFAFA;
  min-height: 92vh;

  &.showForm {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  .navAndContent {
    max-width: 1680px;
    width: 100%;
    margin: 0 auto;
    padding: 15px 30px 0px 30px;
  }
}