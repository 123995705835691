
.creditApp-initialPage {
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-origin: border-box;
  background-size: cover;

  font-family: "source-sans-pro";
  padding-top: 96px;

  > div {
    grid-template-rows: repeat(2, auto);
    gap: 2.8rem;
    max-width: 960px;

    > div {
      grid-template-columns: repeat(2, 1fr);
      box-shadow: 0px 8px 24px 0px #00000026;
    }
  }

  .leftSide {
    padding: 40px;
    background-color: #FAFAFA;

    h3 {
      margin-bottom: 40px;
    }
  }

  .rightSide {
    padding: 40px 90px;
    padding-bottom: 80px;
    grid-template-rows: 1fr auto auto;
    gap: 48px;

    p {
      font-size: 16px;
    }
  }

  input:focus {
    border: 1px #FFD204 solid;
    caret-color: #FFD204;
  }
  
  .startButton{
    margin: 0 auto;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #FFD204;
    font-weight: 400;
    font-size: 16px;
  }
}

.addPaymentForm {
  white-space: nowrap;
}
