.textInputSmall input {
  width: 75px;
}
.textInputMedium input {
  width: 160px;
}
.textInputLarge input {
  width: 250px;
}
.textInputNoLimit {
  width: 100%;
}