
.topBarNavigator {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  vertical-align: top;
  width: fit-content;
  font-size: 16px;
  border-radius: 99px;
  background-color: #f0f0f0;
  ul {
    display: flex;
    gap: 16px;
  }
  li {
    cursor: pointer;
    font-size: 16px;
    min-width: 178px;
    text-align: center;
    border-right: 4px rgba(0,0,0,0) solid;
    border-radius: 50px;
    height: 34px;
    padding: 5px 16px;
    &:hover{
      background-color: rgba(255, 210, 4, 0.48)
    }
  }
  li.selected {
    background-color: #FFD204;
  }
}