nav a.logo img {
  height: 45px;
}

nav a.logo span {
  font-size: 35px;
  line-height: 45px;
}

#nav-hamburger-check:checked ~ #nav-header #nav-hamburger-open {
  display: none;
}

#nav-hamburger-check:not(:checked) ~ #nav-header #nav-hamburger-close {
  display: none;
}

nav #nav-hamburger-check:not(:checked) ~ #nav-content {
  display: none;
}

@screen xl {
  nav #nav-hamburger-check:not(:checked) ~ #nav-content {
    display: block;
  }
}