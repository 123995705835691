.shareLink.centered {
  display: flex;
  justify-content: center;
  position: relative;
}

input.shareLink-link{
  border: 1px solid #343333;
  margin-right: 8px;
  padding: 2px 8px;
  border-radius: 4px;
  margin-top: 0px;
}
.shareLink-window{
  .x{
    right: 5px;
    margin-top: 5px;
    margin-right: 10px;
    font-size: 28px;
    color: #555555;
  }
  button.copyLinkButton{
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
  }
  position: absolute;
  background-color: white;
  width: 610px;
  padding: 7px 24px 14px 24px;
  margin-top: 7px;
  z-index: 10;
  box-shadow: 0px 3px 5px 0px rgba(109, 110, 113, 0.32);
  border-radius: 8px;
}
.shareLink-window:not(.centered){
  right: 20px;
}
.shareLink-window.centered{
  top: 35px;
}