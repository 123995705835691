.radio {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 9px;
}

.radio-small {
    width: 15px;
    height: 15px;
    margin: 5px;
}

.radio::after {
    content: "";
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: none;
    z-index: -1;
}

.radio-small::after {
    width: 23px;
    height: 23px;
}

.radio.disabled{
    border: 2px solid #f0f0f0;
}

.radio:not(.valid):not(.disabled) {
    border: 2px solid #F45C5C;
}
.radio.checked:not(.valid):not(.disabled) {
    background-color: #F45C5C;
}

.radio:not(.disabled):hover:after {
    display: block;
    top: -21px;
	left: -9px;
    @apply bg-en-yellow-200
}

.radio-small:not(.disabled):hover:after {
    top: -13px;
    left: -6px;
}

.radio:not(.checked):not(.disabled):hover:after {
    display: block;
    top: -9px;
	left: -9px;
    @apply bg-en-gray-100
}

.radio-small:not(.checked):not(.disabled):hover:after {
    top: -6px;
    left: -6px;
}

.radio::before {
    content: "";
    display: block;
    position: relative;
    top: 7px;
	left: 7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
    z-index: 1;
}
.radio.checked:not(.disabled)::before{
    background: white;
}
.radio.checked.disabled::before{
    background: #f0f0f0;
}
.radio-small::before {
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
}

.radio:not(.checked)::before {
    display: none;
}