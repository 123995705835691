
.borderNormalYellow {
  border: 1px solid #FFD204;
}

.uploadFiles.disabled{
  background-color: transparent;
  border: none;

}

.uploadFiles{
  background-color: white;
  border: 1px solid #CBCBCD;
  h4 {
    line-height: 24px;
    font-size: 24px;
  }
  label {
    padding: 8px 16px 8px 16px;
    border-radius: 50px;
    border-radius: 50px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
  label:hover{
    background-color: #F0F0F0;
    box-shadow: none;
  }
  .file {
    background-color: #F0F0F0;
    border: 1px solid #F0F0F0;
    height: 32px;
    grid-template-columns: 1fr auto;
    padding-right: 7px;
    padding-left: 12px;

    &.uploading {
      grid-template-columns: auto 1fr;
    }

    &.validating{
      background-color: white;
      border: 1px solid #CBCBCD;
    }

    .uploading-bar {

      > div {
        height: 2px;
        background-color: rgba(255, 210, 4, 0.12)
      }

      .progress{
        animation: progress 1s infinite linear;
        transform-origin: 0% 50%;
        background-color: #FFD204;
      }

      @keyframes progress {
        0% {
          transform:  translateX(0) scaleX(0);
        }
        40% {
          transform:  translateX(0) scaleX(0.4);
        }
        100% {
          transform:  translateX(100%) scaleX(0.5);
        }
      }
    }
  }
}