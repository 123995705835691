
.infoTooltipLabel {
  label {
    margin-right: 4px;
  }
  img {
    vertical-align: middle;
    margin-bottom: 4px;
  }
}
