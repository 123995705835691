.documentUploadStep {
  min-height: 448px;
}

.documentUploadStep-leftSide{
  width: 50%;
  gap: 10px;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  // padding: 40px;
}
.documentUploadStep-rightSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px; 
  padding-left: 10px;
  padding-right: 10px;
}
.documentUploadStep-rightSide .inputField{
  max-width: 320px;
}

.documentUploadStep-newRequirementTag {
  height: 12px;
  padding: 0px 3px 12px 3px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
  color: var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  line-height: 13px; /* 144.444% */
  letter-spacing: -0.54px;
  text-transform: uppercase;
  white-space: nowrap;
  &:hover{
    background-color: #f0f0f0;
    cursor: pointer;
  }
}
