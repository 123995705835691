
.featureNavigator {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding: 8px;
  padding-bottom: 4px;
  width: 100%;
  vertical-align: top;
  font-size: 16px;
  ul {
    display: flex;
    gap: 16px;
  }
  li {
    cursor: pointer;
    font-size: 16px;
    text-align: right;
    border-right: 4px rgba(0,0,0,0) solid;
    border-radius: 50px;
    display: flex;
    height: 34px;
    padding: 5px 16px;
  }
  li.selected {
    background-color: #FFD204;
  }
}