.projectsPage {
  left: 0px;
  min-height: 1077px;
  /* UI Properties */
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "source-sans-pro";
  .window{
    margin: 0 auto;
    padding: 24px;
  }
  .archiveButton {
    display: inline-flex;
    height: 32px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #F0F0F0;
    background: white;
    color: #343333;
    font-size: 16px;
  }
  .archiveButton:hover{
    background:#F0F0F0;
  }
}

.addPaymentForm {
  white-space: nowrap;
}

.projectsPage-header{
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}