.spin-when-empty:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid theme("colors.en-yellow.300");
  border-left: 1.1em solid theme("colors.en-yellow.700");
  border-radius: 50%;
  animation: spin-when-empty-animation 1.1s infinite linear;
  z-index: 100;
}

@keyframes spin-when-empty-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
