

.modal.incentivesModal {
  z-index: 100;
  span.checkbox::before{
    top: -11px !important;
  }

  color: #343333;
  label{
    font-size: 16px;
  }
  .rightSide {
    padding: 4px 12px 4px 20px;
  }
  .leftSide, .rightSide{
    margin: 8px 0px 0px 0px;
    min-height: 280px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid #f0f0f0;
  }
  .noIncentivesText {
    padding-top: 10px;
    width: 100%;
    color:#6D6D70;
    font-weight: 300;
  }
  .totalText {
    margin: 8px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .incentivesDropdown {
    color: #6D6D70;

    svg {
      top: 5px;
    }
  }
  .incentivesList {
    li:not(.selected) .amount {
      padding-right: 2px;
    }
    li.selected {
    background-color: #fafafa;
    border-right: 2px solid #FFD204;
      .amount {
        padding-right: 0px;
      }
    }
  }
  input:focus {
    border: 1px #FFD204 solid;
    caret-color: #FFD204;
  }
  .addIncentivesButton {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    background-color: #FAFAFA;
    gap: 16px;
    border: 1px dashed #CBCBCD;
    border-radius: 4px;
    color: #343333;
    font-weight: 300;
    padding: 10px 20px;
      &:hover:not(:disabled) {
        background-color: #f0f0f0;
      }
      &:disabled{
        color: #CBCBCD;
        cursor: default;
      }
  }
  .modal-content {
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    margin: 0 auto;
    position: absolute;
    top: 100px;
    left: 10px;
    right: 10px;
    z-index: 10;
    width: 90%;
    max-width: 840px;
    height: auto;
    padding: 20px 38px;
    label {
      font-weight: 300;
    }
    h2 {
      font-size: 40px;
      font-weight: 300;
      line-height: 64px; /* 160% */
    }
    p.incentiveName, p.totalText {
      margin-bottom: 20px;
      margin-top: 4px;
    }
    .submitBtn {
      margin: 0 auto;
      margin-bottom: 15px;
      span {
        font-weight: 400;
        line-height: 28px;
      }
    }
    .x {
      position: absolute;
      right: -14px;
      top: -12px;
      font-size: 40px;
    }
    .x:hover {
      color: #CBCBCD;
      cursor: pointer;
    }
    .doneButton {
      padding-top: 7px;
      padding-bottom: 8px;
    }
  }
}
.alreadyAppliedText {
  color: #6D6D70;
}