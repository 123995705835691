@tailwind base;

@tailwind components;

@tailwind utilities;

.bg-en-pattern {
  background-color: #FAFAFA;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}

input[type="file"]::file-selector-button {
  cursor: pointer;
}

.gradient-en {
  background-image: linear-gradient(180deg, rgba(255,210,4,1) 0%, rgba(235,168,0,1) 100%);
}
.gradient-dark {
  background-image: linear-gradient(180deg, rgba(93,91,91,1) 0%, rgba(52,51,51,1) 100%);
}
