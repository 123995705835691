
.borderNormalYellow {
  border: 1px solid #FFD204;
}

.uploadFiles.disabled{
  background-color: transparent;
  border: none;

}


h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.bigUploadFiles-file {
  display: flex;
  min-width: 320px;
  min-height: 32px;
  padding: 0px 10px 0px 12px;
  // align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 50px;
  border: 1px solid var(--Text-Light-Text-Text-Disabled, #CBCBCD);
}
.bigUploadFiles-file.validated {
  border-radius: 50px;
  border: 0px;
  background: var(--Common-Background-03-Neutral, #f0f0f0);
}

.bigUploadFiles:not(.disabled){
  border: 1px dashed var(--Others-Divider, #CBCBCD);
  background: var(--Common-Background-02-Paper, #FFF);
  padding: 24px 24px 30px 24px;
}
.bigUploadFiles{
  h4, h5, label, p, span:not(.newRequirementTag) {
    color: #343333
  };
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--Gap-lg, 16px);
  align-self: stretch;
  border-radius: 16px;

  .uploadCard {
    width: 100%;
    display: flex;
    padding: 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: var(--Common-Background-03-Neutral, #FAFAFA);
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--Gap-lg, 16px);
    }
    .selectFilesButton {
      height: 32px;
      padding: 4px 16px;
      margin-left: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: var(--Common-Background-02-Paper, #FFF);
      display: inline-block;
      cursor: pointer;
      

      /* 01 Shadows/Dark/z1 */
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
    }
    .selectFilesButton:hover {
      background: #F0F0F0;
    }
  }
}
.bigUploadFile-refreshIcon {
 path { fill: #6D6D70;}
}

.uploading-bar {

  > div {
    height: 2px;
    background-color: rgba(255, 210, 4, 0.12)
  }

  .progress{
    animation: progress 1s infinite linear;
    transform-origin: 0% 50%;
    background-color: #FFD204;
  }

  @keyframes progress {
    0% {
      transform:  translateX(0) scaleX(0);
    }
    40% {
      transform:  translateX(0) scaleX(0.4);
    }
    100% {
      transform:  translateX(100%) scaleX(0.5);
    }
  }
}