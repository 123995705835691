@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Black/Gotham-Black.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Gotham-Black/Gotham-Black.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Gotham-Black/Gotham-Black.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Gotham-Black/Gotham-Black.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Gotham-Black/Gotham-Black.svg#svgFontName")
      format("svg"); /* Legacy iOS */
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Book/Gotham-Book.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Gotham-Book/Gotham-Book.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Gotham-Book/Gotham-Book.woff")
      format("woff"),
    /* Modern Browsers */ url("./assets/fonts/Gotham-Book/Gotham-Book.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Gotham-Book/Gotham-Book.svg#svgFontName")
      format("svg"); /* Legacy iOS */
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
