.helpPanel {
  position: fixed;
  width: 50%;
  height: 100%;
  top: 0;
  padding: 18px 24px;
  border-left: 2px solid #74C3E1;
  background-color: white;
  right: -2000px;
  transition: 0.5s;
  overflow-y: scroll;
  z-index: 10;
  button .x{
    font-size: 28px;
  }
  .lvl2, .lvl3{
    margin-top: 20px;
  }
  ul {
    margin-left: 40px;
    list-style-type: disc;
  }
}
@media(max-width: 1200px){
  .helpPanel {
    width: 70%;
  }
}
@media(max-width: 800px){
  .helpPanel {
    width: 90%;
  }
}

.helpPanel.open {
  transition: 0.5s;
  right: 0px
}
.helpPanel.close {
  right: -2000px;
  transition: 0.5s;
}

.helpRibbon {
  border-radius: 8px;
  background: #CBF3FE;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    min-width: 20px;
    min-height: 20px;
  }
}