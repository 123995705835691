.autocomplete__option {
  @apply py-2 px-4 text-xs;
}


.autocomplete__multiple-input-wrapper {
  @apply flex flex-wrap border;
}


.autocomplete__multiple-input-wrapper--disabled {
  outline: 0;
}

.autocomplete__multiple-input-wrapper--not-empty {
  padding: 0 !important;
}

.autocomplete__multiple-input {
  box-sizing: border-box;
  width: 0;
  min-width: 30px;
  flex-grow: 1;
  border: 0;
  margin: 0;
  outline: 0;
  padding-left: 4px;
}

.autocomplete__options-wrapper {
  max-height: 120px;
  overflow-y: auto;
}

#autocomplete-popper {
  z-index: 100;
}

#autocomplete-popper > div {
  z-index: inherit;
}