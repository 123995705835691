.switchBlue{
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 12px;
  label{
    color: #343333;
    font-weight: 700;
    margin-right: 6px;
  }  
  input {
    background-color: #f0f0f0;
    position: relative;
    top: 6px;
  }
  input:before {
    background-color: #fafafa;
  }
  input:checked {
    background-color: #74C3E13D;
  }
  input:checked:before {
    background-color: #74C3E1;
  }
}
.switchDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: right;
}
.wavingHandOff{
  path{
    fill: #CBCBCD;
  }
}
.wavingHandOn {
  animation: hithere 1s ease 1s 2;
  @keyframes hithere {
    30% { transform: scale(1.2); }
    40%, 60% { transform: rotate(-20deg) scale(1.2); }
    50% { transform: rotate(20deg) scale(1.2); }
    70% { transform: rotate(0deg) scale(1.2); }
    100% { transform: scale(1); }
  }
}  