

.snackBar > div{
  font-family: source-sans-pro;
  color: #343333;
  white-space: normal;
  p {
    font-weight: 600;
    font-size: 14px
  }
  > div {
    flex-wrap: wrap;
    span{
      font-size: 14px
    }
  }
  box-shadow: 0px 3px 5px 0px rgba(109, 110, 113, 0.32);
  .snackBar-messageList{
    list-style-position: inside;
  }
  .snackBar-fieldName{
    color: #74C3E1;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
  }
}
.snackBar.error > div{
  background-color: #FFE7D9;
  > div {
    align-content: center;
  }
}
.snackBar.info.withFieldLabel > div > div:first-child{
  padding-top: 12px;
}
.snackBar.info > div{
  background-color: #fff;
  svg path {
    fill: #74C3E1;
  }
}