#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
    left: calc(50% - 10px);
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
    left: calc(50% - 10px);
}

#tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
    top: calc(50% - 10px);
}

#tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
    top: calc(50% - 10px);
}
