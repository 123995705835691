
.almostDonePanel.twoColumns {
  max-width: 695px;

}
.almostDonePanel {
  font-size: 16px;
  .gray2{
    color: #6D6D70;
  }
  padding: 32px;
  gap: 20px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 64px; /* 160% */
  }
  p {
    margin-bottom: 20px;
  }
  .submitBtn {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    min-width: 160px;
    display: flex;
    justify-content: center;
    span {
      font-weight: 600;
      line-height: 28px;
    }
  }
  .taskCompleteIcon{
    margin-left: 2px;
    margin-right: 10px;
  }
}