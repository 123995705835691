.companiesPage {
  left: 0px;
  min-height: 1077px;
  /* UI Properties */
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "source-sans-pro";
  .window{
    margin: 0 auto;
    padding: 24px;
  }
}

.addPaymentForm {
  white-space: nowrap;
}

.companiesPage-header{
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}