
.expandableTable {
  font-size: 14px;
  margin-bottom: 8px;
  .loadMore{
    border: 1px #f0f0f0 solid;
    border-top: 0px;
    height: 50px;
    background: none;
    box-shadow: none;
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    padding: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    div{
      line-height: 20px;
      margin: 0px;
      padding: 0px;
      position: relative;
      top: 2px;
    }
    img {
      margin: 0 auto;
    }
  }
  .loadMore:hover {
    background-color: #f0f0f0;
  }
  tr {
    border: 1px #f0f0f0 solid;
  }
  td, th{
    padding: 10px
  }
  th {
    text-align: left;
  }
}