
.formNavigator {
  display: flex;
  height: 47px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  .actions {
  }
  ul {
    display: flex;
  }
  li {
    cursor: pointer;
    padding: 5px 12px 17px 12px;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    border-right: 2px rgba(0,0,0,0) solid;
  }
  li.selected {
    font-weight: 400;
    border-bottom: 2px #ffd204 solid;
    z-index: 1;
  }
}