.spinner {
  border-width: 4px;
  border-style: solid;
  border-color: theme("colors.en-gray.900");
  border-right-color: transparent !important;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
  margin-right: 1rem;
  margin-left: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
