.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%; 
  overflow: auto;
  background-color: rgba(0,0,0,0.1);
  padding: 20px;
  
  .modal-content{
    p, button {
      font-size: 16px;
    }
    background-color: #fefefe;
    // margin: 15% auto;
    padding: 20px;
    position: fixed;
    white-space: normal;
    width: 50%;
    border-radius: 4px;
    box-shadow: 0px 3px 5px 0px rgba(109, 110, 113, 0.32);
  }
}

.blurred *:not(:has(.shouldNotBlur), .shouldNotBlur, .shouldNotBlur *) {
  filter: blur(3px);
}