$yellow-1: #FFD204;

.customerInfoStep {
  color: #343333;
  input, select{
      font-size: 16px;
      max-width: 260px;
      min-width: 120px;
  }
  input.switch{
    border: none;
    max-width: 32px;
    min-width: 32px;
  }
  .inputField-projectName {
      // border: none;
      // border-bottom: 1px solid;
      width: 490px;
      input { 
          border-radius: 0px;
          border-top: none;
          border-left: none;
          border-right: none;
          font-weight: 300;
          line-height: 36px;
          font-size: 24px;
          max-width: 490px;
          padding-right: 30px;
      }
  }
  .title{
      font-size: 24px;
      padding-bottom: 20px;
      color: #343333;
      font-style: normal;
      font-weight: 300;
  }
  .information{
      font-size: 16px;
      font-weight: 400;
      white-space: normal;
  }
  .addStreetAddress{
      display: inline-flex;
      height: 32px;
      padding: 5px 16px 5px 8px;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0;
      border-radius: 50px;
      border-width: 0px;
      gap: 8px;
      flex-shrink: 0;
  }
  .addStreetAddress:hover{
      background-color: #e0e0e0;
  }
  .textButton {
      position: relative;
      top: -5px;
      span {
      padding-top: 1px;
      }
      color: #343333;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      background-color: #FFD204;
      border-radius: 4px;
      padding: 6px 16px;
      margin-top: 20px;
      width: 90px;
  }
  .inputField, .radioField {
      display: inline-block;
      margin-right: 20px;
      width: 200px;
      input:focus {
        border: 1px $yellow-1 solid;
        caret-color: $yellow-1;
      }
  }
  .radioField {
    position: relative;
    top: 2px;
  }
  .inputField-small {
      display: inline-block;
      margin-right: 20px;
      width: 130px;

  }
  //select field arrow size
  .inputField svg {
      width: 24px;
      top: 2px;
  }
  .isTaxExemptField{
    margin-bottom: 10px;
  }
  .error input, input.error {
    border-color: #ee3b3b;
  }
}