
.legalBusinessSearch{
  background: linear-gradient(270deg,#1A2250, #80CAFF, #FFD204);
  border-radius: 4px;
  .legalBusinessSearch-input{
    width: 400px;
    height: 48px;
    border-radius: 4px;
    background-color: white;
    border: none !important;
    outline: none;
    margin: 1px;
    padding-right: 50px;
    padding-left: 10px;
    &:focus + .legalBusinessSearch-button svg {
      background-color: #FFD204; 
    }
    
  }
  .legalBusinessSearch-button {
    &:hover svg{
      background-color: #FFD204; 
    }
    > div {
      width: 40px;
      height: 40px;
      margin-top: 5px;
      margin-right: 5px;
    }
    svg {
      width: 100%;
      height: 100%;
      padding: 6px;
      path {
        fill: white;
      }
      border-radius: 4px;
      background-color: #f0f0f0;
    }
  }
}

.businessInfoStep {
  min-height: 512px;

  .selectField {
    padding-right: 20px;
  }
}

.businessInfoStep-leftSide{
  width: 50%;
  gap: 10px;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
.businessInfoStep-rightSide {
  width: 50%;
  border-left: 1px solid #CBCBCD;
  display: flex;
  flex-direction: column;
  gap: 16px; 
  padding: 20px 40px;
}
.businessInfoStep-rightSide .inputField{
  max-width: 320px;
}


.businessInfoStep-backToSearch{
  background: linear-gradient(270deg,#1A2250, #80CAFF, #FFD204);
  border-radius: 4px;
  width: 250px;
  cursor: pointer;
  > div{
    width: 248px;
    border-radius: 4px;
    border: none !important;
    outline: none;
    margin: 1px;
    padding-right: 8px;
    padding-left: 8px;
    background-color: #fafafa;
    
  }
}