


.project-details {
  height: 350px;
  table.mainTable {
    color: #343333;
    display: block;
    td:first-child {
      width: 400px;
    }
    tr {
      line-height: 18px;
    }
    > tbody > tr > td:last-child {
      padding-right: 20px;
    }
    > tr:last-child > td {
      padding-bottom: 20px;
    }

    > tbody > tr {
      td:first-child {
          padding-left: 20px;
      }
      > td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 8px;
        padding-left: 8px;
      }
    }
    .totalIncentivesRow > td {
        padding-bottom: 4px;
      }
    .projectIncentivesRow{ 
      > td {
        padding-top: 4px;
        padding-bottom: 0px;
      } 
    }
    .lumpSumDescriptionRow {
      background-color: white;
      font-size: 12px;
      > td {
        padding-top: 4px;
        padding-left: 0px;
      }
    }
  }

  .incentivesTable{
    background-color: #fafafa;
    td {
      padding: 2px 4px;
    }
    td:last-child {
      padding-right: 8px;
    }
    tr:not(.lumpSumDescriptionRow) {
      td:first-child {
        padding-left: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}

.project-details {
  font-size: 20px;
}