
.window {
  position: relative;
  top: 15px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  
  opacity: 1;
  min-width: 800px;
  max-width: 1300px;
  width: 97%;
  white-space: normal;
  margin-bottom: 60px;
  font-family: "source-sans-pro";
}
h4{
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}
h4.font-normal{
  font-weight: 400;
}

.s14{
  font-size: 14px;
}
.s18{
  font-size: 18px;
}

.s24{
  font-size: 24px;
}

.userName{
  padding-top: 8px;
}
h3{
  font-size: 32px;
  font-weight: 300;
  line-height: 40px; /* 125% */
}

//---------------
.creditApp-form.partnerView {
  padding-top: 15px;
  > div {
    padding-top: 24px;
  }
}
.creditApp-form {
  font-size: 16px;
  left: 0px;
  /* UI Properties */
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "source-sans-pro";

  > div {
    background: white;
    box-shadow: 0px 1px 7px 0px rgba(109, 110, 113, 0.32);
    border-radius: 5px;
    max-width: 1680px;
    width: 100%;
  }

  .actions {
    display: flex;
    gap: 24px;
  }

  .city{
    max-width: 49%;
  }
  
  input[type=text]:not(.border-en-red):not(:disabled), .selectField{
    border: 1px solid #6D6D70;
  }
  input[type=text]:not(.border-en-red):not(:disabled):focus{
    border: 1px #FFD204 solid;
    caret-color: #FFD204;
  }

  .selectField {
    svg {
      top: 5px;
    }
  }
  .twoSides {
    display: flex;
  }
  .leftSide{
    width: 50%;
    gap: 10px;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    padding: 40px;
  }
  .rightSide {
    width: 50%;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    gap: 16px; 
    padding: 40px;
  }
  .window{
    margin: 0 auto;
    box-shadow: 0px 8px 24px 0px #00000026;

  }
  input:focus {
    border: 1px #FFD204 solid;
    caret-color: #FFD204;
  }

  .inputField{
    min-width: 60px;
    width: 100%;
  }
  .inputField svg {
    bottom: 0;
    margin: auto 2px;
  }
  .inputField.short {
    min-width: 40px;
  }
  .inputField.maxShort {
    > div {
      max-width: 120px;
    }
  }
  .inputField.long {
    min-width: 80px;
  }
  .inputField.yearsInBusiness input {
    width: 100%;
  }
  .partnerLogo{
    max-width: 80%;
    margin: 0 auto;
  }
  .startButton{
    margin: 0 auto;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #FFD204;
    font-weight: 400;
    font-size: 16px;
  }
  .validatedDocuments{ 
    svg {
      path {
        fill: #98CB65
      }
    }
  }

  .tabAndLine{
    position: relative;
  }
  .tabNavigator {
  padding-right: 24px;
  margin-left: 24px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  ul {
    display: flex;
  }
  li {
    cursor: pointer;
    padding: 5px 12px 17px 12px;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    border-right: 2px rgba(0,0,0,0) solid;
  }
  li.selected {
    font-weight: 400;
    border-bottom: 2px #ffd204 solid;
  }}
  .lastModifiedRibbon{
    width: 98%;
    margin: 10px auto;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background-color: rgba(26, 34, 80, 0.04);
    text-align: center;
    font-size: 14px;
  }
}

.addPaymentForm {
  white-space: nowrap;
}
