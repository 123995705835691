
.adminPage {
    position: relative;
    top: 15px;
    width: 900px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 24px #95959529;
    border-radius: 5px;
    opacity: 1;
    margin: 0 auto;
    input {
        font-size: 16px;
    }
    button.yellow {
        display: flex;
        margin-top: 8px;
        padding: 8px 16px;
        justify-content: left;
        border-radius: 4px;
        background-color: #FFD204;
        font-weight: 400;
        font-size: 16px;
      }
    svg {
        top: 5px;
    }
    .inviteUsers input {
        border: none !important;
    }
}