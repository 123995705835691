
.dropdownMenu {
  font-size: 16px;
  .dropdownMenu-option {
    padding: 8px;
    padding-right: 16px;
  }
  .dropdownMenu-option.hoverEffect:hover {
    background-color: #f0f0f0;
  }
  .dropdownMenu-label.disabled {
    color: #CBCBCD;
  }
}
.dropdownMenu-content{
  box-shadow: 0px 4px 8px 0px rgba(109, 110, 113, 0.32);
  gap: 10px;
  border: 0px;
  border-radius: 4px;
  white-space: nowrap;
  right: 70px;
}
