

.almostDoneFieldsLeft {
  > li{
    &::before {
      content: '>';
      color: #F45C5C;
      padding-right: 5px;
    }
  }
}