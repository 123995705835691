.implementationDetailsStep {
  .constructionPeriodField {
    position: relative;
    .textInputMedium ~ span {
      position: absolute;
      right: 36px;
      top: 34px;
    }
  }
}

.downPaymentsField{
  width: 360px;
  margin-right: 8px;
  display: inline-block;
}