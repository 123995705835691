
.panel {
  padding: 32px;
  gap: 20px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
  // max-width: 695px;
  border-radius: 8px;
  background-color: white;

  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 64px; /* 160% */
  }
  p {
    margin-bottom: 20px;
  }
  .submitBtn {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    span {
      font-weight: 400;
      line-height: 28px;
    }
  }
}