.switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width:32px;
  height: 16px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  @apply bg-en-gray-200;
  transition: background-color ease 0.3s;
  margin: 12px;
}

.switch:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  @apply bg-en-gray-500;
  left: -12px;
  top: -4px;
  border-radius: 50%;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.switch:checked {
  @apply bg-en-yellow-400;
}

.switch:checked:before {
  left: 20px;
  @apply bg-en-yellow-700;
}

.switch.disabled {
  @apply bg-en-gray-200;
  cursor: not-allowed;
}

.switch.disabled:before {
  @apply bg-en-gray-100;
}

.switch.disabled:checked {
  @apply bg-en-yellow-400;
  cursor: not-allowed;
}

.switch.disabled:checked:before {
  @apply bg-en-yellow-300;
}