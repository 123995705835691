
.s12{
    font-size: 12px;
}
.s16{
    font-size: 16px;
}
.s18{
    font-size: 18px;
}
.s20{
    font-size: 20px;
}
.s24{
    font-size: 24px;
}
.s28{
    font-size: 28px;
}
.s54{
    font-size: 54px;
}
.s64{
    font-size: 64px;
}

.borderYellow{
    border: 2px #FFD303 solid;
    border-radius: 10px;
}

.textYellow{
    color: #FFD303;
}
.textDarkGray{
    color: #3F3F3F;
}

.pageBorder{
    border: 24px #f0f0f0 solid;
}

.font-heavy {
    font-weight: 600;
}

.proposal-gen {
    .lineSeparator{
        border: 1px #FFD204 solid;
        border-bottom: none;
    }
    .pageSeparator {
        height: 24px;
        width: 100%;
        background-color: #f0f0f0;
    }
    .title{
        font-size: 32px;
        font-weight: 200;
        font-style: italic;
    }
    .keyBenefitsContent{
        padding:14px;
    }
    .monthlyCashflowChart{
        height: 350px;
        width: 420px;
    }
    .table-footer {
        box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.48);
        -webkit-box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.48);
        -moz-box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.48);
    }
    .legend {
        .legendItem {
            min-width: 160px;
        }
    }
}

.proposal-gen > div {
    break-inside: avoid-page;
}