
.searchField{
.searchInput {
  font-size: 16px;
  border-radius: 999px;
  width: 360px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #343333;
  outline: none;
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.searchIcon {
  margin-top: 6px;
  margin-left: 12px;
}
.searchInput:focus{
  border: 1px #FFD204 solid;
  caret-color: #FFD204;
}
.xIconDiv {
  display: inline-block;
  position: relative;
  width: 0px;
  height: 0px;
  top: -12px;
  left: -30px;
}
.xButton:hover {
  svg path {
    fill: #B0B0B0;
  }
}
}