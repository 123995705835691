.getHelpButton {
  max-height: 34px;
  white-space: nowrap;
}
.getHelpIcon {
  height: 18px;
  margin-left: 4px;
  path {
    fill: #1A2250;
  }
}
.taskCompletedIcon{
  margin-left: 1px;
  margin-right: 3px;
}



.validatedDocumentsDiv{
  display: flex;
  flex-direction: column;
  gap: var(--Gap-sm, 8px);
  align-self: stretch;
  border-radius: 16px;
}
.requiredDocumentsList {
  margin: 0 auto;
  max-width: 480px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 6px 12px;
}
.newRequirementTag {
  height: 12px;
  padding: 0px 3px;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
  color: var(--EnPowered-Secondary-05-Blue-Dark, #1A2250);
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  line-height: 13px; /* 144.444% */
  letter-spacing: -0.54px;
  text-transform: uppercase;
  white-space: nowrap;
}

.fadein {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}