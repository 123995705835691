
.downPayment-month{
  background-color: #CBCBCD;
  font-weight: 600;
  border-radius: 100px;
  width: 24px;
}

.downPayment{
  margin: 1px 1px;
  border-radius: 100px;
  height: 30px;
  text-align: center;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  display: flex;
  padding: 4px 6px 4px 5px;
  align-items: center;
  gap: 4px;
  background-color: #F0F0F0;
  svg {
    width: 12px;
    height: 10px;
  }
  button:hover {
    svg path{
      fill: #CBCBCD;
    }
  }
}
.progressPayments {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.newDownPayment{
  display: flex;
  height: 30px;
  justify-content: space-evenly;
  border-radius: 100px;
  border: 1px #FFD204 solid;
  padding: 0px 2px;
  margin: 1px 1px;

  .newDownPayment-percentage{
    max-width: 70px;
    span{
      right: 0px
    }
    // input:focus{
    // }
    input {
      color: #343333;
      // color: #CBCBCD;
      text-align: center;
      width: 30px;
      padding-right: 0px;
      padding-left: 0px;
      height: 24px;
      border: none;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.35px;
    }
  }
  .newDownPayment-month{
    input{
      text-align: center;
      font-size: 16px;
      color: white;
      display: flex;
      width: 24px;
      height: 24px;
      padding: 3px 0px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: #FFD204;
    }
  }
  button {
    margin-right: 1px;
    svg {
      width: 20px;
      height: 24px;
      padding-left: 1px;
    }
  }
  button:hover {
    svg path{
      fill: #434345;
    }
  }
}

.addDownPayment:hover{
  background-color: #ffcf00a6;
}

.addDownPayment{
  margin: 1px 1px;
  padding: 6.4px 16px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 6.4px;
  border-radius: 40px;
  background: #FFD2043D;
  white-space: nowrap;
  margin-right: 4px;
  vertical-align: middle;
  img {
    vertical-align: top;
  }
  span{
    margin-right: 4px;
  }
}

.finalPayment{
  white-space: nowrap;
  margin: 1px;
  display: flex;
  justify-content: space-evenly;
  span {
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
  div {
    display: inline-block;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-weight: 400;
    line-height: 22px;
    border: 1px solid #F0F0F0;
  }
  border-left: 1px solid #6D6D70;
  padding: 0px 6px;
}