

.modal.submitConfirmationModal .modal-content {
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  position: absolute;
  top: 100px;
  left: 10px;
  right: 10px;
  z-index: 10;
  width: 90%;
  max-width: 840px;
  height: auto;
  padding: 20px 38px;
  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 64px; /* 160% */
  }
  p {
    margin-bottom: 20px;
  }
  .submitBtn {
    margin: 0 auto;
    margin-bottom: 15px;
    span {
      font-weight: 400;
      line-height: 28px;
    }
  }
  .x {
    position: absolute;
    right: -14px;
    top: -12px;
    font-size: 40px;
  }
  .x:hover {
    color: #CBCBCD;
    cursor: pointer;
  }
}