@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  height: 100%;
  font-size: 13.5px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

body {
  background-color: #FAFAFA;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-disabled-clean input[type="text"]:disabled,
.input-disabled-clean input[type="email"]:disabled,
.input-disabled-clean select.select-container:disabled {
  border: white;
  color: rgb(74, 85, 104);
  background-color: rgba(239, 239, 239, 0.3);
}

.input-gray-placeholder input[type="text"]::placeholder {
  color: #343333;
}

#root {
  background-color: rgba(255, 254, 246);
}
