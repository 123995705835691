li.nav-item input[type=checkbox].nav-item-check:checked ~ .nav-item-button .nav-item-toggle {
    @apply transform rotate-180;
}

li.nav-item input[type=checkbox].nav-item-check:not(:checked) ~ .nav-item-child {
    display: none;
}

li.nav-item input[type=checkbox].nav-item-check:checked ~ .nav-item-button .nav-item-toggle {
    display: none;
}

li.nav-item input[type=checkbox].nav-item-check:not(:checked) ~ .nav-item-button .nav-item-toggle {
    display: block;
}

li.nav-item input[type=checkbox].nav-item-check:not(:checked) ~ .nav-item-button .nav-item-line {
    display: none;
}

@screen xl {
    li.nav-item input[type=checkbox].nav-item-check:checked ~ .nav-item-button .nav-item-toggle {
        display: block;
    }
}