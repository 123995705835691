.topBar {
  background-color: white;
  img {
      position: relative;
      left: -7px;
      margin-right: 30px;
  }
}

.loggedInDiv{
  display: flex;
  align-items: center;
  gap: 24px;
  .roundButton{
    font-size: 12px;
    cursor: pointer;
  }
  .roundIcon {
    margin: 0 auto;
    border-radius: 999px;
    padding: 2px 2px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected {
    border: 1px solid #FFC94F;
  }
  .dropdownMenu.profile {
    margin-right: 10px;
    .iconTextButton{
      background-color: #FAFAFA;
      color: #343333;
      font-family: "source-sans-pro";
      font-size: 18px;
      font-weight: 600;
      border-radius: 999px;
      padding: 2px 2px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      // border: none;
    }
    .dropdownMenu-content{
      right: 15px;
      font-weight: 300;
    }
  }
}